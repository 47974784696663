<template>
  <b-modal
    :id="`commonStatementsModal-${queryID}`"
    modal-class="common-statements-modal"
    size="xl"
    @shown="loadInitData()"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer
  >
    <v-wait for="loadingStatements">
      <template slot="waiting">
        <content-placeholders :rounded="true" class="pt-4">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>

      <b-card class="std-border reports-card" body-class="" header-class="pl-3">
        <div slot="header">
          <div class="d-flex align-items-center">
            <span class="pr-2 icon-layers"></span>
            <h6 class="mb-0"><b>Financial Statements</b></h6>
          </div>
        </div>

        <div class="row" v-if="!hideFiTypeSelector && fiType !== 'both'">
          <div class="offset-sm-9 col-sm-3">
            <div class="row align-items-baseline text-right">
              <div class="col-sm-4 font-weight-bold">
                FI Type :
              </div>
              <div class="col-sm-8 report-output-view pl-0 pr-1">
                <Multiselect
                  track-by="value"
                  label="text"
                  v-model="statementFIType"
                  :allowEmpty="false"
                  :showLabels="false"
                  :options="fiTypeOptions"
                  class="rw-multi-select aq-multiselect"
                  @select="fiTypeUpdate"
                ></Multiselect>
              </div>
            </div>
          </div>
        </div>

        <div class="row no-gutters financial-statements-segment mb-3">
          <div class="col-sm-3">
            <h6 class="">Statement Categories</h6>
            <div
              class="card std-border mb-0 financial-statements-menu tree-menu segment-column"
            >
              <div class="card-body p-0">
                <div
                  v-for="(statementGroup, index) in treeData"
                  :key="index"
                  class="pb-3"
                >
                  <div
                    :class="[
                      {
                        active: selectedStatement.data === statementGroup.data,
                        'cursor-pointer': !hasSubMenu(statementGroup)
                      },
                      'font-weight-bold text-uppercase px-2 pt-2 menu-header'
                    ]"
                    @click="selectStatementGroup(statementGroup)"
                  >
                    {{ statementGroup.text }}
                  </div>
                  <ul class="list-group" v-if="statementGroup.children.length">
                    <li
                      :class="[
                        {
                          active: selectedStatement.data === childStatement.data
                        },
                        'list-group-item pl-4'
                      ]"
                      v-for="(childStatement, index) in statementGroup.children"
                      :key="index"
                      @click="selectStatement(childStatement)"
                    >
                      {{ childStatement.text }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div :class="['col-sm-9', 'px-2 details-holder']">
            <h6 class="" v-if="selectedStatement.product_name">
              {{ selectedStatement.product_name }}:
              {{ selectedStatement.menu_display }}
            </h6>
            <h6 v-else class="mb-4"></h6>
            <div class="card std-border mb-0 statement-details segment-column">
              <div class="card-body p-1">
                <v-wait for="loadingTreeData">
                  <template slot="waiting">
                    <content-placeholders :rounded="true" class="loader">
                      <content-placeholders-text
                        :lines="20"
                      ></content-placeholders-text>
                    </content-placeholders>
                  </template>

                  <div class="row p-3">
                    <div
                      class="offset-sm-4 col-sm-2 text-right align-self-center"
                    >
                      <h6 class="mb-0">Search</h6>
                    </div>

                    <div class="col-sm-6 pl-0">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Type text to search..."
                        v-model="searchText"
                      />
                    </div>
                  </div>

                  <LiquorTree
                    :data="selectedStatementData"
                    :multiple="false"
                    :showChildren="true"
                    :options="treeOptions"
                    :filter="searchText"
                    @node:clicked="nodeClicked"
                    ref="statementsTree"
                  >
                    <span class="tree-text" slot-scope="{ node }">
                      <template>
                        <span :id="'fm-' + node.id.toString()">
                          {{ node.text }}
                        </span>
                        <b-popover
                          :target="'fm-' + node.id.toString()"
                          triggers="hover"
                          placement="right"
                          boundary="window"
                          v-if="node.data.sec_title"
                        >
                          <div class="font-weight-bold">
                            {{ node.data.sec_title }}
                          </div>
                          {{ node.data.description }}
                        </b-popover>
                      </template>
                    </span>
                  </LiquorTree>
                </v-wait>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 d-flex justify-content-center">
            <div class="px-1">
              <button
                type="button"
                class="btn btn-secondary"
                @click="$bvModal.hide(`commonStatementsModal-${queryID}`)"
              >
                Cancel
              </button>
            </div>
            <div class="px-1">
              <button
                type="button"
                class="btn btn-primary"
                @click="updateStatementSelection"
                :disabled="!(selectedStatementID || selectedNode)"
              >
                Set Statement
              </button>
            </div>
          </div>
        </div>
      </b-card>
    </v-wait>
  </b-modal>
</template>

<script charset="utf-8">
// global
import { mapState, mapMutations } from 'vuex'
//api
import financialStatementsAPI from '@/api/finapps/financial_statements'
// ui components
import LiquorTree from 'liquor-tree'
import Multiselect from 'vue-multiselect'
// utilities
import _ from 'lodash'
import { findDeep } from 'deepdash'

export default {
  name: 'CommonStatementsModal',
  components: {
    LiquorTree,
    Multiselect
  },
  props: {
    queryID: {
      type: String,
      required: true
    },
    selectedStatementID: {
      required: false
    },
    fiType: {
      type: String,
      required: true
    },
    segmentableElements: {
      type: Array,
      required: false
    },
    hideFiTypeSelector: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      selectedStatement: {},
      selectedStatementGroup: {},
      selectedStatementData: {},
      searchText: null,
      selectedNode: null,
      treeData: [],
      fiTypeOptions: [
        { value: 'bank', text: 'Bank' },
        { value: 'credit_union', text: 'Credit Union' }
      ],
      statementFIType: { value: 'bank', text: 'Bank' },
      treeOptions: {
        checkbox: false,
        multiple: false,
        checkOnSelect: true,
        parentSelect: true,
        autoCheckChildren: false,
        autoDisableChildren: false
      }
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      financialStatementsData: state => state.financialStatementsData,
      financialCommonStatementsData: state =>
        state.financialCommonStatementsData
    }),
    statementsByFIType() {
      return _.groupBy(this.financialStatementsData, s => s.fi_type)
    },
    financialStatements() {
      if (this.fiType === 'both') {
        return this.financialCommonStatementsData
      } else {
        return this.statementsByFIType[this.fiType]
      }
    },
    treeID() {
      return this.fiType === 'bank' ? 1 : 3
    },
    groupedMenuData() {
      return _.groupBy(this.metricMenuData, m => `${m.fi_type}_${m.tree_id}`)
    },
    menuData() {
      let menu = this.groupedMenuData[`${this.fiType}_${this.treeID}`]
      return _.groupBy(menu, 'product_name')
    }
  },
  methods: {
    ...mapMutations('ReportWriter', [
      'setFinancialStatementsData',
      'setFinancialCommonStatementsData'
    ]),
    loadInitData() {
      return this.getFinancialStatementDetails().then(() => {
        this.updateFIType()
        this.createStatementsTree()
        this.$nextTick(() => {
          this.$refs.statementsTree.setModel(this.treeData)
          this.setDefaultStatement()
        })
      })
    },
    getFinancialStatementDetails() {
      if (
        this.financialStatementsData.length &&
        this.financialCommonStatementsData.length
      ) {
        return Promise.resolve()
      }

      this.$wait.start('loadingStatements')

      return financialStatementsAPI
        .all()
        .then(res => {
          this.setFinancialCommonStatementsData(res.common_statements)
          this.setFinancialStatementsData(res.statements)
        })
        .finally(() => {
          this.$wait.end('loadingStatements')
        })
    },
    updateFIType() {
      this.statementFIType = this.fiTypeOptions.find(
        opt => opt.value === this.fiType
      )
    },
    createStatementsTree() {
      this.treeData = this.financialStatements.map(fs =>
        this.createStatementNode(fs)
      )
    },
    createStatementNode(statement, parentName) {
      parentName = !parentName ? statement.name : parentName
      if (statement.children && statement.children.length) {
        let node = this.getNodeData(statement, parentName)
        let sortedChildrens = statement.children.order
          ? statement.children.sort((a, b) => (a.order > b.order ? 1 : -1))
          : statement.children

        const create = name => child => this.createStatementNode(child, name)
        node.children = sortedChildrens.map(create(statement.name))

        return node
      } else if (statement.tree) {
        let node = this.getNodeData(statement, parentName)
        node.children = this.createStatementNode(statement.tree, statement.text)
        return node
      } else {
        return this.getNodeData(statement, parentName)
      }
    },
    findStatement(menu) {
      return findDeep(
        menu.children,
        function(fs) {
          return fs.data.element_id == this.selectedStatementID
        }.bind(this),
        {
          childrenPath: 'children'
        }
      )
    },
    setDefaultStatement() {
      if (this.selectedStatementID) {
        this.selectedMetricID = parseInt(this.selectedMetricID)
        this.treeData.every(mainMenu => {
          let matchingStatement = null

          if (this.hasSubMenu(mainMenu)) {
            mainMenu.children.every(subMenu => {
              matchingStatement = this.findStatement(subMenu)

              if (matchingStatement) {
                this.selectedStatement = subMenu
              }

              return matchingStatement ? false : true
            })
          } else {
            matchingStatement = this.findStatement(mainMenu)

            if (matchingStatement) {
              this.selectedStatement = mainMenu
            }
          }

          if (matchingStatement) {
            this.selectStatement(this.selectedStatement)
            return false
          } else {
            return true
          }
        })
      } else {
        this.selectedStatement = this.treeData[0].children[0]
        this.selectedStatementGroup = this.selectedStatement.text
        this.selectStatement(this.selectedStatement)
      }
    },
    getNodeData(statement, parentName) {
      return {
        id: statement.id,
        text: statement.name,
        data: {
          treeID: statement.tree_id,
          fiType: statement.fi_type,
          value: statement.value,
          parent_id: statement.parent_id,
          element_id: statement.element_id,
          parentName: parentName
        },
        state: {
          selected: this.isStatementSelected(statement),
          expanded: true,
          checked: false
          //disabled: this.isDisable(statement.element_id)
          //visible: !this.isDisable(statement.element_id)
        }
      }
    },
    isDisable(id) {
      if (this.segmentableElements) {
        return this.segmentableElements.includes('' + id) ? false : true
      } else return false
    },
    isStatementSelected(statement) {
      if (this.selectedNode) {
        return this.selectedNode.id === statement.id
      } else if (this.selectedStatementID) {
        return (
          parseInt(this.selectedStatementID) === parseInt(statement.element_id)
        )
      } else {
        return false
      }
    },
    nodeClicked(node) {
      this.selectedNode = node
    },
    selectStatement(statement) {
      this.searchText = null
      this.selectedStatement = statement

      let matchStatement = findDeep(
        this.financialStatements,
        function(fs) {
          return fs.id === statement.id
        },
        {
          childrenPath: 'children'
        }
      )

      let subTree = this.createStatementNode(matchStatement.value.tree)
      this.$refs.statementsTree.setModel(subTree)
    },
    selectStatementGroup(statement) {
      this.selectedStatementGroup = statement.text
      if (!this.hasSubMenu(statement)) {
        this.selectStatement(statement)
      }
    },
    hasSubMenu(statement) {
      return statement.children.length
    },
    updateStatementSelection() {
      let fi = ''
      if (this.fiType !== 'both') {
        fi = this.statementFIType.text === 'Bank' ? 'Bank' : 'CU'
      }
      this.selectedNode.parentName =
        this.fiType !== 'both'
          ? fi + ' - ' + this.selectedStatement.data.parentName
          : this.selectedStatement.data.parentName
      this.selectedNode.service =
        this.fiType !== 'both'
          ? fi + ' - ' + this.selectedStatement.text
          : this.selectedStatement.text
      this.$emit('updateStatement', this.selectedNode)
      this.$bvModal.hide(`commonStatementsModal-${this.queryID}`)
    },
    fiTypeUpdate(option) {
      this.$emit('updateFIType', option.value)

      this.$nextTick(() => {
        this.selectedNode = null
        this.searchText = null
        this.createStatementsTree()
        this.selectedStatement = this.treeData[0].children[0]
        this.selectStatement(this.selectedStatement)
      })
    }
  }
}
</script>

<style lang="scss"></style>
