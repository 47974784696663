<template>
  <b-modal
    :id="`commonMetricTreeModal-${queryID}`"
    modal-class="common-metric-tree-modal"
    size="xl"
    @shown="loadInitData()"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer
  >
    <v-wait for="loadingMetricsData">
      <template slot="waiting">
        <content-placeholders :rounded="true" class="pt-4">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>

      <b-card
        class="std-border reports-card"
        body-class="p-1"
        header-class="pl-3"
      >
        <div slot="header">
          <div class="d-flex align-items-center">
            <span class="pr-2 cui-spreadsheet"></span>
            <h6 class="mb-0"><b>Financial Metrics (Bank/CU)</b></h6>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <b-card no-header class="mb-0" body-class="">
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="">Metric Categories</h6>
                  <div
                    class="card std-border mb-0 metric-menu tree-menu segment-column"
                  >
                    <div class="card-body p-0">
                      <div class="pt-2">
                        <ul
                          class="common-metric-list-group pl-0 font-weight-bold"
                        >
                          <li
                            :class="[
                              {
                                active:
                                  selectedMetric.product_id ===
                                  product.product_id
                              },
                              'py-1 list-group-item pl-3'
                            ]"
                            v-for="(product, index) in metricsMenu"
                            :key="index"
                            @click="selectProduct(product)"
                          >
                            {{ product.name }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div :class="['col-sm-9', 'px-2 details-holder']">
                  <h6 class="" v-if="selectedMetric.name">
                    {{ selectedMetric.name }}
                  </h6>
                  <div
                    class="card mb-0 std-border metric-details segment-column"
                  >
                    <div class="card-body py-0">
                      <div class="row p-3">
                        <div
                          class="offset-sm-4 col-sm-2 text-right align-self-center"
                        >
                          <h6 class="mb-0">Search</h6>
                        </div>

                        <div class="col-sm-6 pl-0">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Type text to search..."
                            v-model="searchText"
                          />
                        </div>
                      </div>

                      <LiquorTree
                        :data="treeData"
                        :multiple="false"
                        :showChildren="true"
                        :options="treeOptions"
                        :filter="searchText"
                        @node:clicked="nodeClicked"
                        @node:checked="nodeChecked"
                        @node:unchecked="nodeUnChecked"
                        ref="commonMetricsTree"
                      >
                        <span
                          :class="[
                            selectedMetricID &&
                            selectedMetricID === node.data.id
                              ? 'highlight-node'
                              : '',
                            'tree-text'
                          ]"
                          slot-scope="{ node }"
                        >
                          <template>
                            <span :id="'cm-' + node.id.toString()">
                              {{ node.text }}
                            </span>

                            <b-popover
                              :target="'cm-' + node.id.toString()"
                              triggers="hover"
                              placement="right"
                              boundary="window"
                            >
                              <div class="font-weight-bold">
                                {{ node.data.bank_sec_title }}
                              </div>
                              {{
                                getMatchingDescription(
                                  node.data.bank_metric_id,
                                  node.data.cu_metric_id
                                ).bank_description
                              }}

                              <div class="font-weight-bold mt-3">
                                {{ node.data.cu_sec_title }}
                              </div>
                              {{
                                getMatchingDescription(
                                  node.data.bank_metric_id,
                                  node.data.cu_metric_id
                                ).cu_description
                              }}
                            </b-popover>
                          </template>
                        </span>
                      </LiquorTree>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 d-flex justify-content-center mb-3">
            <div class="px-1">
              <button
                type="button"
                class="btn btn-secondary"
                @click="$bvModal.hide(`commonMetricTreeModal-${queryID}`)"
              >
                Cancel
              </button>
            </div>
            <div class="px-1">
              <button
                type="button"
                class="btn btn-primary"
                @click="updateMetricSelection"
                :disabled="!(selectedMetricID || selectedNode)"
              >
                Set Metric
              </button>
            </div>
          </div>
        </div>
      </b-card>
    </v-wait>
  </b-modal>
</template>

<script charset="utf-8">
// global
import { mapState, mapMutations } from 'vuex'
//api
import commonMetricsMappingsAPI from '@/api/finapps/common_metrics_mappings'
// ui components
import LiquorTree from 'liquor-tree'

export default {
  name: 'MetricTreeModal',
  components: {
    LiquorTree
  },
  props: {
    queryID: {
      type: String,
      required: true
    },
    selectedMetricID: {
      required: false
    },
    segmentableElements: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      treeData: [],
      searchText: null,
      selectedNode: null,
      selectedMetric: {},
      treeOptions: {
        checkbox: false,
        multiple: false,
        checkOnSelect: true,
        parentSelect: true,
        autoCheckChildren: false,
        autoDisableChildren: false
      },
      metricsMenu: [
        {
          product_id: 1,
          name: 'Profit'
        },
        {
          product_id: 2,
          name: 'Growth'
        },
        {
          product_id: 3,
          name: 'Risk'
        }
      ],
      metricDescriptions: null
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      commonMetricTreeData: state => state.commonMetricTreeData
    }),
    productMetrics() {
      if (!this.selectedMetric) {
        return []
      }
      return this.commonMetricTreeData.filter(
        td => td.product_id === this.selectedMetric.product_id
      )
    },
    productParentMetrics() {
      let parentElements = []

      this.productMetrics.forEach(pm => {
        let metricParent = this.productMetrics.find(
          metric => metric.key_id === pm.key_parent_id
        )
        if (!metricParent) {
          let isDuplicate = parentElements.find(el => el.id === pm.id)

          if (!isDuplicate) {
            parentElements.push(pm)
          }
        }
      })
      return parentElements
    }
  },
  methods: {
    ...mapMutations('ReportWriter', ['setCommonMetricTreeData']),
    loadInitData() {
      this.loadMetricTreeDetails()
    },
    loadMetricTreeDetails() {
      this.$wait.start('loadingMetricsData')

      Promise.all([
        this.getMetricsData(),
        this.getMetricTreeDescriptions()
      ]).then(() => {
        this.$wait.end('loadingMetricsData')

        this.$nextTick(() => {
          this.$nextTick(() => {
            this.setSelectedMetric()
            this.prepareTree()
          })
        })
      })
    },
    getMetricsData() {
      if (this.commonMetricTreeData.length) {
        return Promise.resolve()
      }

      this.$wait.start('loadingCommonTreeData')

      return commonMetricsMappingsAPI
        .metrics()
        .then(res => {
          return this.setCommonMetricTreeData(res.common_metrics)
        })
        .finally(() => {
          this.$wait.end('loadingCommonTreeData')
        })
    },
    getMetricTreeDescriptions() {
      if (this.metricDescriptions) {
        return Promise.resolve()
      }

      return commonMetricsMappingsAPI.metricDescriptions().then(res => {
        this.metricDescriptions = res.common_metrics
      })
    },
    getMatchingDescription(bankMetricID, cuMetricID) {
      if (!this.metricDescriptions) {
        return {}
      }

      return (
        this.metricDescriptions.find(
          m =>
            m.bank_metric_id === bankMetricID && m.cu_metric_id === cuMetricID
        ) || {}
      )
    },
    prepareTree() {
      this.treeData = this.productParentMetrics.map(ppm => {
        return this.createTreeNode(ppm, this.productMetrics)
      })

      if (this.$refs.commonMetricsTree) {
        this.$refs.commonMetricsTree.setModel(this.treeData)
      }
    },
    createTreeNode(metric, metrics) {
      return {
        id: metric.id,
        text: metric.name,
        data: metric,
        state: {
          selected:
            this.selectedMetricID && this.selectedMetricID === metric.id
              ? true
              : false,
          expanded: true,
          checked:
            this.selectedMetricID && this.selectedMetricID === metric.id
              ? true
              : false
          //disabled: this.isDisable(metric.id)
          //visible: !this.isDisable(metric.id)
        },
        children: this.convertToTree(metrics, metric.key_id)
      }
    },
    isDisable(id) {
      if (this.segmentableElements) {
        return this.segmentableElements.includes('' + id) ? false : true
      } else return false
    },
    convertToTree(metrics = [], id = null, link = 'key_parent_id') {
      return metrics
        .filter(metric => metric[link] === id)
        .map(metric => this.createTreeNode(metric, metrics))
    },
    setSelectedMetric() {
      if (this.selectedMetricID) {
        this.selectedMetricID = parseInt(this.selectedMetricID)
        let metric = this.commonMetricTreeData.find(
          cm => cm.id === this.selectedMetricID
        )
        this.selectedMetric = this.metricsMenu.find(
          mm => mm.product_id === metric.product_id
        )
      } else {
        this.selectedMetric = this.metricsMenu[0]
      }
    },
    selectProduct(menu) {
      this.selectedMetric = menu
      this.searchText = null
      this.prepareTree()
    },
    nodeClicked(node) {
      this.selectedNode = node
    },
    nodeChecked() {},
    nodeUnChecked() {},
    updateMetricSelection() {
      this.selectedNode.parentName = this.selectedMetric.name

      this.$emit('updateMetric', this.selectedNode)
      this.$bvModal.hide(`commonMetricTreeModal-${this.queryID}`)
      this.treeData = null
    }
  }
}
</script>
