<template>
  <b-modal
    :id="`metricTreeModal-${queryID}`"
    modal-class="metric-tree-modal"
    size="xl"
    @shown="loadInitData()"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer
  >
    <v-wait for="loadingMetricsData">
      <template slot="waiting">
        <content-placeholders :rounded="true" class="pt-4">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>

      <b-card
        class="std-border reports-card"
        body-class="p-1"
        header-class="pl-3"
      >
        <div slot="header">
          <div class="d-flex align-items-center">
            <span class="pr-2 cui-spreadsheet"></span>
            <h6 class="mb-0"><b>Financial Metrics</b></h6>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <b-card no-header class="mb-0">
              <div class="row" v-if="!hideFiTypeSelector">
                <div class="offset-sm-9 col-sm-3">
                  <div class="row align-items-baseline text-right">
                    <div class="col-sm-4 font-weight-bold">
                      FI Type :
                    </div>
                    <div class="col-sm-8 report-output-view pl-0 pr-1">
                      <Multiselect
                        track-by="value"
                        label="text"
                        v-model="metricFIType"
                        :allowEmpty="false"
                        :showLabels="false"
                        :options="fiTypeOptions"
                        class="rw-multi-select aq-multiselect"
                        @select="fiTypeUpdate"
                      ></Multiselect>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="">Metric Categories</h6>
                  <div
                    class="card std-border mb-0 metric-menu tree-menu segment-column"
                  >
                    <div class="card-body p-0">
                      <div>
                        <div
                          v-for="(menuItems, productName) in menuData"
                          :key="productName"
                          class="pb-3"
                        >
                          <div
                            class="font-weight-bold text-uppercase px-2 pt-2"
                          >
                            {{ productName }}
                          </div>
                          <ul class="list-group">
                            <li
                              :class="[
                                {
                                  active:
                                    selectedMetric.menu_display ===
                                    menu.menu_display
                                },
                                'list-group-item pl-4'
                              ]"
                              v-for="(menu, index) in menuItems"
                              :key="index"
                              @click="setActive(menu)"
                            >
                              {{ menu.menu_display }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div :class="['col-sm-9', 'px-2 details-holder']">
                  <h6 class="" v-if="selectedMetric.product_name">
                    {{ selectedMetric.product_name }}:
                    {{ selectedMetric.menu_display }}
                  </h6>
                  <div
                    class="card std-border mb-0 metric-details segment-column"
                  >
                    <div class="card-body p-1">
                      <v-wait for="loadingTreeData">
                        <template slot="waiting">
                          <content-placeholders :rounded="true" class="loader">
                            <content-placeholders-text
                              :lines="20"
                            ></content-placeholders-text>
                          </content-placeholders>
                        </template>

                        <div class="row p-3">
                          <div
                            class="offset-sm-4 col-sm-2 text-right align-self-center"
                          >
                            <h6 class="mb-0">Search</h6>
                          </div>

                          <div class="col-sm-6 pl-0">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Type text to search..."
                              v-model="searchText"
                            />
                          </div>
                        </div>

                        <LiquorTree
                          :data="treeData"
                          :multiple="false"
                          :showChildren="true"
                          :options="treeOptions"
                          :filter="searchText"
                          @node:clicked="nodeClicked"
                          @node:checked="nodeChecked"
                          @node:unchecked="nodeUnChecked"
                          ref="metricTree"
                        >
                          <span class="tree-text" slot-scope="{ node }">
                            <template>
                              <span :id="'fm-' + node.id.toString()">
                                {{ node.text }}
                              </span>
                              <b-popover
                                :target="'fm-' + node.id.toString()"
                                triggers="hover"
                                placement="right"
                                boundary="window"
                                v-if="node.data.sec_title"
                              >
                                <div class="font-weight-bold">
                                  {{ node.data.sec_title }}
                                </div>
                                {{
                                  getMatchingDescription(node.data.metric_id)
                                    .description
                                }}
                              </b-popover>
                            </template>
                          </span>
                        </LiquorTree>
                      </v-wait>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 d-flex justify-content-center mb-3">
            <div class="px-1">
              <button
                type="button"
                class="btn btn-secondary"
                @click="$bvModal.hide(`metricTreeModal-${queryID}`)"
              >
                Cancel
              </button>
            </div>
            <div class="px-1">
              <button
                type="button"
                class="btn btn-primary"
                @click="updateMetricSelection"
                :disabled="!(selectedMetricID || selectedNode)"
              >
                Set Metric
              </button>
            </div>
          </div>
        </div>
      </b-card>
    </v-wait>
  </b-modal>
</template>

<script charset="utf-8">
// global
import { mapState, mapMutations } from 'vuex'
// api
import reportMenuAPI from '@/api/finapps/report_menu_items'
import metricTreeRelationshipsAPI from '@/api/finapps/metric_tree_relationships'
// ui components
import LiquorTree from 'liquor-tree'
import Multiselect from 'vue-multiselect'
// utilities
import _ from 'lodash'
import { findDeep } from 'deepdash'

export default {
  name: 'MetricTreeModal',
  components: {
    LiquorTree,
    Multiselect
  },
  props: {
    queryID: {
      type: String,
      required: true
    },
    selectedMetricID: {
      required: false
    },
    fiType: {
      type: String,
      required: true
    },
    segmentableElements: {
      type: Array,
      required: false
    },
    hideFiTypeSelector: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      treeData: null,
      searchText: null,
      selectedNode: null,
      selectedMetric: {},
      selectedMetricData: {},
      fiTypeOptions: [
        { value: 'bank', text: 'Bank' },
        { value: 'credit_union', text: 'Credit Union' }
      ],
      metricFIType: { value: 'bank', text: 'Bank' },
      treeOptions: {
        checkbox: false,
        multiple: false,
        checkOnSelect: true,
        parentSelect: true,
        autoCheckChildren: false,
        autoDisableChildren: false
      }
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      metricMenuData: state => state.metricMenuData,
      metricTreeData: state => state.metricTreeData
    }),
    treeID() {
      return this.fiType === 'bank' ? 1 : 3
    },
    groupedMenuData() {
      return _.groupBy(this.metricMenuData, m => `${m.fi_type}_${m.tree_id}`)
    },
    menuData() {
      let menu = this.groupedMenuData[`${this.fiType}_${this.treeID}`]
      return _.groupBy(menu, 'product_name')
    },
    productMetrics() {
      if (!this.selectedMetric) {
        return []
      }
      return this.metricTreeData[this.treeID].filter(
        td => td.product_id === this.selectedMetric.product_id
      )
    },
    selectedOption() {
      return this.productMetrics.find(
        pm => pm.metric_id === this.selectedMetric.metric_id
      )
    },
    metricIDsToBeSkipped() {
      if (!this.selectedMetric) {
        return []
      }

      return this.menuData[this.selectedMetric.product_name]
        .filter(md => md.metric_id !== this.selectedMetric.metric_id)
        .map(md => md.metric_id)
    }
  },
  methods: {
    ...mapMutations('ReportWriter', ['setMetricMenuData', 'setMetricTreeData']),
    loadInitData() {
      this.loadMetricTreeDetails()
    },
    loadMetricTreeDetails() {
      this.$wait.start('loadingMetricsData')

      Promise.all([
        this.getMetricMenu(),
        this.getMetricTreeData(),
        this.getMetricTreeDescriptions()
      ]).then(() => {
        this.updateFIType()
        this.setMatchingMenu()
        this.$wait.end('loadingMetricsData')

        this.$nextTick(() => {
          this.$nextTick(() => {
            this.prepareTree()
          })
        })
      })
    },
    getMetricMenu() {
      if (this.metricMenuData.length) {
        return Promise.resolve()
      }

      this.$wait.start('loadingMenu')

      return reportMenuAPI
        .all()
        .then(res => {
          this.setMetricMenuData(res.menu_items)
        })
        .finally(() => {
          this.$wait.end('loadingMenu')
        })
    },
    getMetricTreeData() {
      if (Object.keys(this.metricTreeData).length) {
        return Promise.resolve()
      }

      this.$wait.start('loadingTreeData')

      return metricTreeRelationshipsAPI
        .treeRelationships(this.treeID)
        .then(res => {
          this.setMetricTreeData(res.relationships)
        })
        .finally(() => {
          this.$wait.end('loadingTreeData')
        })
    },
    getMetricTreeDescriptions() {
      if (this.metricDescriptions) {
        return Promise.resolve()
      }

      return metricTreeRelationshipsAPI.metricDescriptions().then(res => {
        this.metricDescriptions = res.relationships
      })
    },
    getMatchingDescription(metricID) {
      if (!this.metricDescriptions) {
        return {}
      }

      return (
        this.metricDescriptions[this.treeID].find(
          m => m.metric_id === metricID
        ) || {}
      )
    },
    updateFIType() {
      this.metricFIType = this.fiTypeOptions.find(
        opt => opt.value === this.fiType
      )
    },
    setMatchingMenu() {
      this.selectedMetricID = parseInt(this.selectedMetricID)
      let selectedMetricData = this.metricTreeData[this.treeID].find(
        md => md.metric_id === this.selectedMetricID
      )

      if (this.selectedMetricID && selectedMetricData) {
        let possibleMenus = this.metricMenuData.filter(
          mtd =>
            mtd.fi_type === this.fiType &&
            mtd.tree_id === this.treeID &&
            mtd.product_id === selectedMetricData.product_id
        )

        let matchingRule = null

        possibleMenus.forEach(menu => {
          if (!matchingRule) {
            this.selectedMetric = menu

            let metricTree = this.createTreeNode(
              this.selectedOption,
              this.productMetrics
            )

            matchingRule = findDeep(
              metricTree,
              function(rule) {
                return rule.id === selectedMetricData.id
              },
              { childrenPath: 'children' }
            )
          }
        })
      } else {
        this.setDefaultMenu()
      }
    },
    setDefaultMenu() {
      let [firstProduct] = Object.keys(this.menuData)
      this.selectedMetric = this.menuData[firstProduct][0]
    },
    prepareTree() {
      this.treeData = this.createTreeNode(
        this.selectedOption,
        this.productMetrics
      )

      if (this.$refs.metricTree) {
        this.$refs.metricTree.setModel(this.treeData)
      }
    },
    createTreeNode(metric, metrics) {
      return {
        id: metric.id,
        text: metric.tree_display,
        data: metric,
        state: {
          selected: this.isMetricSelected(metric),
          expanded: true,
          checked: false
          //disabled: this.isDisable(metric.id)
          //visible: !this.isDisable(metric.id)
        },
        children: this.convertToTree(metrics, metric.key_id)
      }
    },
    isDisable(id) {
      if (this.segmentableElements) {
        return this.segmentableElements.includes('' + id) ? false : true
      } else return false
    },
    isMetricSelected(metric) {
      if (this.selectedNode) {
        return this.selectedNode.id === metric.id
      } else if (this.selectedMetricID) {
        return parseInt(this.selectedMetricID) === parseInt(metric.metric_id)
      } else {
        return false
      }
    },
    convertToTree(metrics = [], id = null, link = 'key_parent_id') {
      return metrics
        .filter(
          metric =>
            metric[link] === id &&
            !this.metricIDsToBeSkipped.includes(metric.metric_id)
        )
        .map(metric => this.createTreeNode(metric, metrics))
    },
    setActive(menu) {
      this.selectedMetric = menu
      this.searchText = null
      this.prepareTree()
    },
    nodeClicked(node) {
      this.selectedNode = node
    },
    nodeChecked() {},
    nodeUnChecked() {},
    updateMetricSelection() {
      let fi = this.selectedMetric.fi_type === 'bank' ? 'Bank' : 'CU'
      this.selectedNode.parentName =
        fi + ' - ' + this.selectedMetric.product_name
      this.$emit('updateMetric', this.selectedNode)
      this.$bvModal.hide(`metricTreeModal-${this.queryID}`)
      this.treeData = null
    },
    fiTypeUpdate(option) {
      this.$emit('updateFIType', option.value)

      this.$nextTick(() => {
        this.selectedNode = null
        this.setMatchingMenu()
        this.searchText = null
        this.prepareTree()
      })
    }
  }
}
</script>

<style lang="scss"></style>
